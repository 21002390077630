.broken-glass-background {
    background-image: url("./images/broken-glass.png");
    background-size: cover;
    background-position: center;
    position: fixed;
    width: 75%;
    height: 75%;
    top: 0;
    right: 0;
    transform: translateX(25%) translateY(-25%);
    opacity: 0.4;
}

.http-error {
    width: 100%;
    text-align: center;
    position: fixed;
    top: 50%;
    transform: translateY(-70%);
    color: #fff;
}

.http-error-title {
    font-size: 48px;

    @media(min-width: $mobile-breakpoint) {
        font-size: 72px;
    }
}

.http-error-message {
    font-size: 18px;
    font-weight: 100;

    @media(min-width: $mobile-breakpoint) {
        font-size: 36px;
    }
}

.http-error-link-back {
    margin-top: 50px;

    a{
        @include hoverTransition();

        color: #fff;
        font-size: 24px;
        padding: 15px;
        background-color: $uni-red;

        &:hover {
            text-decoration: none;
            background-color: darken($uni-red, 5);
        }
    }
}
