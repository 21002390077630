// Breakpoints
$mobile-breakpoint: 768px;
$desktop-breakpoint: 992px;
$hd-breakpoint: 1200px;

// Variables
$default-margin: 15px;
$header-height: 68px;
$footer-height: 75px;
$transition-speed: 150ms;
