@import "shared/_all-shared";
@import "~bootstrap/scss/bootstrap";

header.main-header {
  width: 100%;
  height: $header-height;
  display: flex;
  padding: $default-margin;
  justify-content: space-between;

  div.header-content-container {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img.logo-image {
          max-height: 100%;
          max-width: 250px;
      }

      &.header-content-container-left {
          justify-content: flex-start;
      }

      &.header-content-container-right {
          display: none;
          justify-content: flex-end;

          @media (min-width: $mobile-breakpoint) {
              display: flex;
          }
      }
  }
}

.main-content {
  padding: calc(#{$default-margin} * 2) 0;
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
}

footer.main-footer {
  height: $footer-height;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.change-language-link {
  margin-left: $default-margin;
  display: flex;
  align-items: center;

  svg {
      height: 1.5rem;
      margin-right: 5px;
  }
}

.app-title {
  font-size: 24px;
  margin: 0 0 25px 0;
}
